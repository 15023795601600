import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import OrderModel from '../../../Models/OrderModel';
import orderService from '../../../Services/orderService';
import FrontendConfig from '../../../Models/FrontendConfig';
import './ConfigEdit.css';
import config from '../../../Utils/config';
import axios from 'axios';
import notifyService from '../../../Services/NotifyService';
import configService from '../../../Services/configService';

function ConfigEdit() {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { register, handleSubmit, formState, } = useForm<FrontendConfig>({ shouldUnregister: true });

  async function send(conf: FrontendConfig) {
    await configService.updateConfig(conf);
  }

  return (
    <div className="ConfigEdit">
      <h3>הגדרות</h3>
      <form onSubmit={handleSubmit(send)}>
        <fieldset disabled={!isEditing}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>מחיר בסיס</th>
                <th>מחיר בפועל</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>משלוח</th>
                <td>
                  <input type="number" defaultValue={config.shipment_cost_base} {...register("shipment_cost_base", { required: { value: true, message: "חייב להכניס מחיר" }, min: { value: 0, message: "לא יכול להיות שלילי כפרה" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.shipment_cost_base?.message}</span></>}
                </td>
                <td>
                  <input type="number" defaultValue={config.shipment_cost} {...register("shipment_cost", { required: { value: true, message: "חייב להכניס מחיר" }, min: { value: 0, message: "לא יכול להיות שלילי כפרה" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.shipment_cost?.message}</span></>}
                </td>
              </tr>
              <tr>
                <th>פיזי</th>
                <td>
                  <input type="number" defaultValue={config.physical_price} {...register("physical_price", { required: { value: true, message: "חייב להכניס מחיר" }, min: { value: 0, message: "לא יכול להיות שלילי כפרה" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.physical_price?.message}</span></>}
                </td>
                <td>
                  <input type="number" defaultValue={config.physical_discounted_amount} {...register("physical_discounted_amount", { required: { value: true, message: "חייב להכניס מחיר" }, min: { value: 0, message: "לא יכול להיות שלילי כפרה" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.physical_discounted_amount?.message}</span></>}
                </td>
              </tr>
              <tr>
                <th>דיגיטלי</th>
                <td>
                  <input type="number" defaultValue={config.epub_price} {...register("epub_price", { required: { value: true, message: "חייב להכניס מחיר" }, min: { value: 0, message: "לא יכול להיות שלילי כפרה" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.epub_price?.message}</span></>}
                </td>
                <td>
                  <input type="number" defaultValue={config.epub_discounted_amount} {...register("epub_discounted_amount", { required: { value: true, message: "חייב להכניס מחיר" }, min: { value: 0, message: "לא יכול להיות שלילי כפרה" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.epub_discounted_amount?.message}</span></>}
                </td>
              </tr>
              <tr>
              <th>אודיו</th>
              <td>
              <input type="number" defaultValue={config.audio_price} {...register("audio_price", {required: {value: true, message: "חייב להכניס מחיר"}, min: {value: 0, message: "לא יכול להיות שלילי כפרה"}})}></input>
                  {!formState.isValid && <><br/><span className="error-message">{formState.errors.audio_price?.message}</span></>}
              </td>
              <td>
              <input type="number" defaultValue={config.audio_discounted_amount} {...register("audio_discounted_amount", {required: {value: true, message: "חייב להכניס מחיר"}, min: {value: 0, message: "לא יכול להיות שלילי כפרה"}})}></input>
                  {!formState.isValid && <><br/><span className="error-message">{formState.errors.audio_discounted_amount?.message}</span></>}
              </td>
              </tr>
              <tr>
              <th>pdf</th>
              <td>
              <input type="number" defaultValue={config.pdf_price} {...register("pdf_price", {required: {value: true, message: "חייב להכניס מחיר"}, min: {value: 0, message: "לא יכול להיות שלילי כפרה"}})}></input>
                  {!formState.isValid && <><br/><span className="error-message">{formState.errors.pdf_price?.message}</span></>}
              </td>
              <td>
              <input type="number" defaultValue={config.pdf_discounted_amount} {...register("pdf_discounted_amount", {required: {value: true, message: "חייב להכניס מחיר"}, min: {value: 0, message: "לא יכול להיות שלילי כפרה"}})}></input>
                  {!formState.isValid && <><br/><span className="error-message">{formState.errors.pdf_discounted_amount?.message}</span></>}
              </td>
            </tr>
              <tr></tr>
              <tr>
                <th></th>
                <th>מקומי</th>
                <th>בינלאומי</th>
              </tr>
              <tr>
                <th>טלפון</th>
                <td>
                  <input defaultValue={config.phone_number.local} {...register("local_phone", { required: { value: true, message: "חייב להכניס מספר" }, minLength: { value: 10, message: "10 תווים לא פחות ולא יותר" }, maxLength: { value: 10, message: "10 תווים לא פחות ולא יותר" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.local_phone?.message}</span></>}
                </td>
                <td>
                  <input defaultValue={config.phone_number.international} {...register("international_phone", { required: { value: true, message: "חייב להכניס מספר" }, minLength: { value: 12, message: "12 תווים לא פחות ולא יותר" }, maxLength: { value: 12, message: "12 תווים לא פחות ולא יותר" } })}></input>
                  {!formState.isValid && <><br /><span className="error-message">{formState.errors.international_phone?.message}</span></>}
                </td>
              </tr>
            </tbody>
          </table>
          <label>כמות ספרים מקסימלית</label>
          <input type="number" defaultValue={config.max_physical} {...register("max_physical", { required: { value: true, message: "חייב להכניס כמות" }, max: { value: 10, message: "כמה ספרים אתה חושב שאנשים יקנו?" }, min: { value: 0, message: "לא יכול להיות שלילי כפרה" } })}></input>
          {!formState.isValid && <><br /><span className="error-message">{formState.errors.max_physical?.message}</span></>}
          <br />
          <label>להציג באנר?</label>
          <input type="checkbox" defaultChecked={config.showBanner} {...register("showBanner")}></input>
          {!formState.isValid && <><br /><span className="error-message">{formState.errors.showBanner?.message}</span></>}
          <br />
          <label>הודעת באנר</label>
          <textarea defaultValue={config.banner_message} {...register("banner_message", { maxLength: { value: 50, message: "פחות מילים בבקשה" } })}></textarea>
          {!formState.isValid && <><br /><span className="error-message">{formState.errors.banner_message?.message}</span></>}
          <br />
        </fieldset>
        <button>שמור</button>
      </form>
      <button onClick={() => setIsEditing(e => !e)}>{isEditing ? "ביטול" : "ערוך"}</button>
    </div >
  );
}

export default ConfigEdit;

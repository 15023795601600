import { useState } from 'react';
import './OnlyBook.css';
import cover from "../../../Assets/Images/Covers/cover.webp";
import back from "../../../Assets/Images/Covers/back.webp";
import spine from "../../../Assets/Images/Covers/spine.webp";
import wrinkles from "../../../Assets/Images/Covers/wrinkles.webp";
import Book3d from '../Book3d/Book3d';

function getThickness(width: number) {
  return width / 6;
}

type OnlyBookProps = { rotate: number, rotateHover: number, rotationRate: number };

function OnlyBook(props: OnlyBookProps) {
  const [width] = useState<number>(180);

  return (
    <div className="OnlyBook">
      <Book3d
        rotate={props.rotate}
        rotateHover={props.rotateHover}
        width={width}
        thickness={getThickness(width)}
        transitionDuration={props.rotationRate}>
        <img src={cover} alt="Book cover" />
        <img src={spine} alt="Book spine" />
        <img src={wrinkles} alt="Book pages" />
        <img src={back} alt="Book back" />
      </Book3d>
    </div>
  );
}

export default OnlyBook;

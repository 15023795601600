import OnlyBook from '../../BookArea/OnlyBook/OnlyBook';
import './Store.css';
import config from "../../../Utils/config";
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ProductType } from '../../../Models/productTypes';
import epubCover from '../../../Assets/Images/Covers/epubCover.webp';
// import pdfCover from '../../../Assets/Images/Covers/pdfCover.webp';
// import audioCover from '../../../Assets/Images/Covers/audioCover.webp';
import Book3d2d from '../../BookArea/Book3d2d/Book3d2d';

function Store() {
  const [printedAngle, setPrintedAngle] = useState<number>(0);
  // const [audioAngle, setAudioAngle] = useState<number>(0);
  // const [pdfAngle, setPdfAngle] = useState<number>(0);
  const [epubAngle, setEpubAngle] = useState<number>(0);

  useEffect(() => {
    setPrintedAngle(720);
    // setAudioAngle(720);
    // setPdfAngle(720);
    setEpubAngle(720);
  }, []);

  return (
    <div className="Store vertical-center">
      <div>
        <div className='spin-book' onClick={() => setPrintedAngle(cur => cur === 720 ? cur - 180 : cur + 180)}>
          <OnlyBook rotate={printedAngle} rotateHover={-1} rotationRate={5} />
          {config.physical_discounted_amount < config.physical_price ? <div className="price"><span>לאחר הנחה: </span><b>₪{config.physical_discounted_amount}</b><br /><span>מחיר מקורי: </span><s>₪{config.physical_price}</s></div> : <div className="price"><b>₪{config.physical_discounted_amount}</b></div>}
          <div className="selection">
            <NavLink to={`/order/${ProductType.printedBook.replace(" ", "-")}`}>בחר</NavLink>
          </div>
        </div>

        {/* <div className='audio-book' onClick={() => setAudioAngle(cur => cur === 720 ? cur - 360 : cur + 360)}>
          <Book3d2d rotate={audioAngle} rotateHover={-1}><img src={audioCover} alt="Audio book cover" /></Book3d2d>
          {config.audio_discounted_amount < config.audio_price ? <div className="price"><span>לאחר הנחה: </span><b>₪{config.audio_discounted_amount}</b><br /><span>מחיר מקורי: </span><s>₪{config.audio_price}</s></div> : <div className="price"><b>₪{config.audio_discounted_amount}</b></div>}
          <div className="selection">
            <NavLink to={`/order/${ProductType.audioBook.replace(" ", "-")}`}>בחר</NavLink>
          </div>
        </div> */}

        {/* <div className='pdf-book' onClick={() => setPdfAngle(cur => cur === 720 ? cur - 360 : cur + 360)}>
          <Book3d2d rotate={pdfAngle} rotateHover={-1}><img src={pdfCover} alt="PDF book cover" /></Book3d2d>
          {config.pdf_discounted_amount < config.pdf_price ? <div className="price"><span>לאחר הנחה: </span><b>₪{config.pdf_discounted_amount}</b><br /><span>מחיר מקורי: </span><s>₪{config.pdf_price}</s></div> : <div className="price"><b>₪{config.pdf_discounted_amount}</b></div>}
          <div className="selection">
            <NavLink to={`/order/${ProductType.pdf.replace(" ", "-")}`}>בחר</NavLink>
          </div>
        </div> */}

        <div className='epub-book' onClick={() => setEpubAngle(cur => cur === 720 ? cur - 360 : cur + 360)}>
          <Book3d2d rotate={epubAngle} rotateHover={-1}><img src={epubCover} alt="ePub book cover" /></Book3d2d>
          {config.epub_discounted_amount < config.epub_price ? <div className="price"><span>לאחר הנחה: </span><b>₪{config.epub_discounted_amount}</b><br /><span>מחיר מקורי: </span><s>₪{config.epub_price}</s></div> : <div className="price"><b>₪{config.epub_discounted_amount}</b></div>}
          <div className="selection">
            <NavLink to={`/order/${ProductType.ePub.replace(" ", "-")}`}>בחר</NavLink>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Store;

import { useEffect, useState } from 'react';
import { DevTool } from '@hookform/devtools';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CouponModel, { CouponType } from '../../../Models/CouponModel';
import couponService from '../../../Services/CouponService';
import notifyService from '../../../Services/NotifyService';
import ConditionList from './ConditionList';
import './CouponList.css';
import OrderModel from '../../../Models/OrderModel';
import CouponConditions from '../../../Models/ConditionModel';

function CouponList() {
  const [coupons, setCoupons] = useState<CouponModel[]>([]);
  const [privateKey, setPrivateKey] = useState<string>();
  const [sortParam, setSortParam] = useState<string>();
  const [isInverted, setIsInverted] = useState<boolean>(false);
  const [editingCoupon, setEditingCoupon] = useState<CouponModel>(null);
  const [addingCoupon, setAddingCoupon] = useState<boolean>(false);

  const { register, handleSubmit, reset, control, formState, getValues, setValue, watch } = useForm<CouponModel>({ shouldUnregister: true });

  useEffect(() => {
    if (!privateKey) return;
    couponService.getAllCoupons(privateKey).then(c => setCoupons(c)).catch(e => notifyService.error("מפתח שגוי"));
  }, [privateKey])

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const rawKey = e.target.result as string;
      const key = rawKey.replace(/-----BEGIN [^\n]+-----/, '').replace(/-----END [^\n]+-----/, '').replace(/\s+/g, '');
      setPrivateKey(key);
    };
    reader.readAsText(file);
  };

  function sortBy(param: keyof CouponModel) {
    const toInvert = param === sortParam ? !isInverted : false;
    setIsInverted(toInvert);
    setSortParam(param);
    const newCoupons: CouponModel[] = JSON.parse(JSON.stringify(coupons));
    newCoupons.sort((a, b) => (a[param] > b[param] ? 1 : -1) * (toInvert ? -1 : 1));
    setCoupons(newCoupons)
  }

  function handleDoubleClick(coupon: CouponModel) {
    if (addingCoupon) return;
    if (editingCoupon !== null) return;
    setEditingCoupon(coupon);
  }

  function handleAdd() {
    if (addingCoupon) return;
    if (editingCoupon) return;
    if (!privateKey) {
      notifyService.error("חייב להכניס מפתח");
      return;
    }
    setAddingCoupon(true);
  }

  async function saveChanges(coupon: CouponModel) {
    const updatedCoupon = await couponService.updateCoupon(coupon);
    if (!updatedCoupon) return;
    couponService.getAllCoupons(privateKey).then(c => setCoupons(c));
    setEditingCoupon(null);
    reset();
  }

  async function addCoupon(coupon: CouponModel) {
    const addedCoupon = await couponService.newCoupon(coupon);
    if (!addedCoupon) return;
    couponService.getAllCoupons(privateKey).then(c => setCoupons(c));
    setAddingCoupon(false);
    reset();
  }

  async function deleteCoupon(id: string, code: string) {
    if (window.confirm(`למחוק את ${code}?`)) {
      const success = await couponService.deleteCoupon(id);
      if (!success) return;
      couponService.getAllCoupons(privateKey).then(c => setCoupons(c));
    }
  }

  function getAppropriateFunc(coupon: CouponModel): SubmitHandler<CouponModel> {
    const newConds: CouponConditions<OrderModel>[] = [];
    if (coupon?.conditions?.length > 0) {
      for (let c of coupon.conditions) {
        newConds.push({ field: c.field, condition: c.condition, value: OrderModel.GetMemberType(c.field) === "number" ? +c.value : c.value })
      }
    }
    coupon.conditions = newConds;
    if (editingCoupon && !addingCoupon) {
      saveChanges(coupon);
      return;
    }
    if (!editingCoupon && addingCoupon) {
      addCoupon(coupon);
      return;
    }
    notifyService.warn("אין לי מושג מה עשית, אבל זה לא היה אמור לקרות");
  }

  return (
    <div className="CouponList">
      <h3>קופונים</h3>
      <form onSubmit={handleSubmit(getAppropriateFunc)}>
        <table>
          <thead>
            <tr>
              <th onClick={() => { sortBy("code") }}>קוד{sortParam === "code" && (isInverted ? "v" : "^")}</th>
              <th onClick={() => { sortBy("type") }}>סוג{sortParam === "type" && (isInverted ? "v" : "^")}</th>
              <th onClick={() => { sortBy("discount") }}>הנחה{sortParam === "discount" && (isInverted ? "v" : "^")}</th>
              <th>תנאים</th>
              <th>מחק</th>
            </tr>
          </thead>
          <tbody>
            {coupons?.map(c => editingCoupon?.code === c.code
              ?
              <tr key={c.code}>
                <td>
                  <input
                    key={c.code + "code"}
                    defaultValue={c.code}
                    {...register("code", {
                      required: { value: true, message: "קופון חייב קוד" },
                      pattern: {
                        value: /^[A-Z]+$/,
                        message: "הקוד יכול להכיל רק אותיות גדולות"
                      },
                      onChange: (e) => {
                        e.target.value = e.target.value.toUpperCase().replace(/[^A-Z]/g, '');
                      }
                    })}
                  />
                </td>
                <td>
                  <Controller
                    name={"type"}
                    control={control}
                    defaultValue={c.type}
                    rules={{ required: { value: true, message: "קופון חייב סוג" } }}
                    render={({ field: { onChange, value, ref } }) => (
                      <select value={value} ref={ref} onChange={onChange}>
                        {Object.keys(CouponType).map(t => <option key={t} value={t}>{t}</option>)}
                      </select>
                    )} />
                  <Controller
                    name={"id"}
                    control={control}
                    defaultValue={c.id}
                    render={({ field: { ref } }) => (
                      <input hidden value={c.id} ref={ref} />
                    )} />
                </td>
                <td>
                  <Controller
                    name={"discount"}
                    control={control}
                    defaultValue={c.discount}
                    rules={{ required: { value: true, message: "קופון חייב ערך" } }}
                    render={({ field: { onChange, value, ref } }) => (
                      <input key={c.code + "discount"} type="number" onChange={onChange} value={value} ref={ref} />
                    )} />
                </td>
                <td><ConditionList key={c.code + "condList"} formState={formState} getValues={getValues} setValue={setValue} watch={watch} conditions={c.conditions} control={control} code={c.code} selected /> </td>
                <td><button key={c.code + "delete"} type="button" disabled>X</button></td>
              </tr>
              :
              <tr key={c.code} onDoubleClick={() => { handleDoubleClick(c) }}>
                <td>{c.code}</td>
                <td>{c.type}</td>
                <td>{c.discount}</td>
                <td><ConditionList conditions={c.conditions} formState={formState} getValues={getValues} setValue={setValue} watch={watch} control={control} code={c.code} /> </td>
                <td><button type="button" disabled={!(!editingCoupon && !addingCoupon)} onClick={() => deleteCoupon(c.id, c.code)}>X</button></td>
              </tr>)}

            {addingCoupon && <tr>
              <td>
                <input
                  {...register("code", {
                    required: { value: true, message: "קופון חייב קוד" },
                    pattern: {
                      value: /^[A-Z]+$/,
                      message: "הקוד יכול להכיל רק אותיות גדולות"
                    },
                    onChange: (e) => {
                      e.target.value = e.target.value.toUpperCase().replace(/[^A-Z]/g, '');
                    }
                  })}
                />
              </td>
              <td>
                <select {...register("type", { required: { value: true, message: "קופון חייב סוג" } })}>
                  <option disabled selected>select type</option>
                  {Object.keys(CouponType).map(t => <option key={t} value={t}>{t}</option>)}
                </select>
              </td>
              <td><input {...register("discount", { required: { value: true, message: "קופון חייב ערך" } })} /></td>
              <td><ConditionList conditions={[]} formState={formState} getValues={getValues} setValue={setValue} watch={watch} control={control} code={"newCode"} selected /></td>
            </tr>}

            <tr>
              {editingCoupon || addingCoupon ? <td>
                <button>{addingCoupon ? "הוסף" : "שמור שינויים"}</button>
                <button type='button' onClick={() => { setEditingCoupon(null); setAddingCoupon(false); reset(); }}>בטל</button>
              </td> : <td><button type='button' onClick={handleAdd}>+</button></td>}
            </tr>
          </tbody>
        </table>
        <p className='error-message'>{formState.errors?.code?.message}</p>
        <p className='error-message'>{formState.errors?.conditions?.map(cond => cond?.field?.message).join(" ")}</p>
        <p className='error-message'>{formState.errors?.conditions?.map(cond => cond?.condition?.message).join(" ")}</p>
        <p className='error-message'>{formState.errors?.conditions?.map(cond => cond?.value?.message).join(" ")}</p>
        <p className='error-message'>{formState.errors?.type?.message}</p>
        <p className='error-message'>{formState.errors?.discount?.message}</p>
        <DevTool control={control} placement="top-right" />
      </form>
      <br />
      <input type="file" accept=".pem" onChange={handleFileChange} />
    </div >
  );
}

export default CouponList;

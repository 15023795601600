import { useMemo } from 'react';
import './Book3d.css';

// Credit is given to scastiel, the creator of the 'book-cover-3d' npm package which I altered.
// https://github.com/scastiel/book-cover-3d

interface Settings {
  /**
   * Rotation of the book, in degrees.
   */
  rotate: number
  /**
   * Rotation of the book on hover, in degrees.
   */
  rotateHover: number
  /**
   * Perspective value. 600 seems to be a realistic value.
   */
  perspective: number
  /**
   * Duration of rotate animation, in milliseconds.
   */
  transitionDuration: number
  /**
   * Radius of right corners, in pixels.
   */
  radius: number
  /**
   * Book thickness, in pixels.
   */
  thickness: number
  /**
   * Width of the book, in pixels.
   */
  width: number
  /**
   * Offset between the pages and the cover size, in pixels.
   */
  pagesOffset: number
  /**
   * Height of the book, in pixels.
   */
  height?: number
}

interface Props extends Partial<Settings> {
  children: React.ReactNode
}

const getCssForSettings = (uniqueId: string, settings: Settings) => {
  settings.height = getHeight(settings.width);
  return `
    .book-container-${uniqueId} {
      display: flex;
      align-items: center;
      justify-content: center;
      perspective: ${settings.perspective}px;
    }
    
    ${settings.rotateHover < 0 ? "" : `@keyframes initAnimation-${uniqueId} {
      0% {
        transform: rotateY(${settings.rotateHover}deg);
      }
      100% {
        transform: rotateY(${settings.rotate}deg);
      }
    }`}
    
    .book-container-${uniqueId} .book {
      width: ${settings.width}px;
      height: ${settings.height}px;
      position: relative;
      transform-style: preserve-3d;
      transform: rotateY(${settings.rotate}deg);
      transition: transform ${settings.transitionDuration}s ease;
      animation: 1s ease 0s 1 initAnimation-${uniqueId};
    }

    ${settings.rotateHover < 0 ? "" : `.book-container-${uniqueId} .book:hover {
      transform: rotateY(${settings.rotateHover}deg);
    }`}
    
    .book-container-${uniqueId} .book > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      width: ${settings.width}px;
      height: ${settings.height}px;
      transform: translateZ(${settings.thickness / 2}px);
      border-radius: ${settings.radius}px 0 0 ${settings.radius}px;
    }
    
    .book-container-${uniqueId} .book > :nth-child(3) {
      position: absolute;
      left: 0;
      top: ${settings.pagesOffset}px;
      width: ${settings.thickness}px;
      height: ${settings.height - 2 * settings.pagesOffset}px;
      transform: translateX(-${settings.thickness / 2 -
    settings.pagesOffset}px) rotateY(90deg);
    }

    .book-container-${uniqueId} .book > :nth-child(2) {
      position: absolute;
      left: 0;
      top: 0;
      width: ${settings.thickness}px;
      height: ${settings.height}px;
      transform: translateX(${settings.width -
    settings.thickness / 2}px) rotateY(90deg);
    }
    
    .book-container-${uniqueId} .book > :last-child {
      position: absolute;
      top: 0;
      left: 0;
      width: ${settings.width}px;
      height: ${settings.height}px;
      transform: translateZ(${-settings.thickness / 2}px);
      border-radius: ${settings.radius}px 0 0 ${settings.radius}px;
    }
  `
}

function getHeight(width: number) {
  return width * 22 / 14.5;
}

function Book3d({
  children,
  rotate = 30,
  rotateHover = 5,
  perspective = 800,
  transitionDuration = 5,
  radius = 6,
  thickness = 25,
  width = 200,
  pagesOffset = 3,
}: Props): JSX.Element {
  const uniqueId = useMemo(
    () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1),
    [],
  )
  const css = getCssForSettings(uniqueId, {
    rotate,
    rotateHover,
    perspective,
    transitionDuration,
    radius,
    thickness,
    width,
    pagesOffset,
  })

  return (
    <>
      <style>{css}</style>
      <div className={`book-container-${uniqueId}`}>
        <div className="book">{children}</div>
      </div>
    </>
  )
}

export default Book3d;

import './DeliveryInfo.css';
import config from "../../../Utils/config";
import { useState, useRef, useEffect } from 'react';
import orderService from '../../../Services/orderService';
import OrderModel from '../../../Models/OrderModel';
import { AddressModel } from '../../../Models/AddressModel';
import addressService from '../../../Services/addressService';
import { Autocomplete, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../Routing/PageNotFound/PageNotFound';
import Loading from '../../LayoutArea/Loading/Loading';
import Scanner from '../../../Assets/Images/scanner-gif.gif';
import { useForm } from 'react-hook-form';
import CouponModel, { CouponType } from '../../../Models/CouponModel';
import couponService from '../../../Services/CouponService';
import notifyService from '../../../Services/NotifyService';
import { ProductType } from '../../../Models/productTypes';

function DeliveryInfo() {
  const confirmationElement = useRef<HTMLDivElement>(null);
  const { orderType } = useParams();

  const { register, handleSubmit, formState, getValues, setValue, trigger, watch } = useForm<OrderModel>();

  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [cities, setCities] = useState<AddressModel[]>();
  const [streets, setStreets] = useState<AddressModel[]>();
  const [cityJustChanged, setCityJustChanged] = useState<boolean>(false);
  const [waitingForRes, setWaitingForRes] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string>("");

  // const [amount, setAmount] = useState<number>(1);
  const amount = watch("amount");
  const [price, setPrice] = useState<{ original: number, shipping: number, total: number }>({ original: 1, shipping: 0, total: 1 });
  const [dedicate, setDedicate] = useState<boolean>(false);
  const [forSelf, setForSelf] = useState<boolean>(true);
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedStreet, setSelectedStreet] = useState<string>("");
  const [coupon, setCoupon] = useState<CouponModel>(null);
  const [couponCode, setCouponCode] = useState<string>("No_Coupon");

  useEffect(() => {
    if (orderService.needsDelivery(orderType)) {
      addressService.getAllCites().then(c => setCities(c));
    }
    setValue("amount", 1);
    setValue("type", orderType as ProductType);
    setValue("dedication_name", "no_need");
  }, []);


  useEffect(() => {
    const shippingCost = orderService.needsDelivery(orderType) ? (coupon?.type === CouponType.shipping ? coupon.discount : config.shipment_cost) : 0;
    let totalPrice = amount * orderService.getBasePrice(orderType);
    const orderPrice = totalPrice + shippingCost;
    switch (coupon?.type) {
      case CouponType.valueDiscount:
        totalPrice -= coupon.discount;
        break;
      case CouponType.percentDiscount:
        totalPrice *= 1 - (coupon.discount) / 100;
        break;
    }
    totalPrice += shippingCost;
    setValue("price", orderPrice);
    const p = { original: orderPrice, shipping: shippingCost, total: totalPrice }
    setPrice(p);
  }, [amount, coupon])

  useEffect(() => {
    if (!cities) return;
    // const inputElement = autocompleteElement.current.childNodes[0].childNodes[1].childNodes[0] as HTMLInputElement;
    setCityJustChanged(() => true);
    setSelectedStreet("");
    setValue("street", "");
    addressService.getStreetByCity(selectedCity).then(s => { setStreets(s); setCityJustChanged(() => false); });
  }, [selectedCity]);

  function fetchCoupon() {
    if (coupon !== null) {
      notifyService.error("לא ניתן לממש יותר מקופון אחד");
      return;
    }

    couponService.getCoupon(couponCode, getValues()).then(c => {
      console.log(c);
      if (c.type === CouponType.shipping && !orderService.needsDelivery(orderType)) {
        notifyService.error("קןפון לא שמיש");
        return;
      }
      setCoupon(c);
      notifyService.success("מומש בהצלחה!");
    }).catch(err => notifyService.error("קוד קופון אינו תקין"));
  }

  async function send(order: OrderModel): Promise<void> {
    order.time = (new Date()).getTime();
    order.coupon_code = couponCode;
    order.price = price.total;

    console.log(order);
    setWaitingForRes(true);
    const { order_number } = await orderService.newOrder(order);
    if (!order_number) return;
    setOrderNumber(order_number);
    setWaitingForRes(false);
    setIsHidden(() => false);
    setDisableButton(() => false);
    // reset();
  }

  useEffect(() => { confirmationElement?.current?.scrollIntoView({ behavior: 'smooth' }); }, [isHidden])

  function sanitize(ev: any): string {
    ev.target.value = ev.target.value.replace(/[^0-9]/, '');
    return ev.target.value;
  }

  return (
    <>
      {price.total <= 0 && <PageNotFound />}
      {price.total > 0 && <div className={`DeliveryInfo `}>
        {(cities || !orderService.needsDelivery(orderType)) &&
          <form onSubmit={handleSubmit(send)}>
            <h2>אנא מלא/י את פרטי ההזמנה</h2>
            <div className='form-container'>
              <div className='first'>
                <TextField
                  label="שם פרטי *"
                  variant="outlined"
                  {...register("first_name", {
                    required: { value: true, message: "שדה זה הוא שדה חובה" },
                    minLength: { value: 2, message: "אנא הכנס שני תווים לפחות" },
                    maxLength: { value: 50, message: "השם הפרטי ארוך מידי" }
                  })} />
                {!formState.isValid && <span className="error-message">{formState.errors.first_name?.message}</span>}
              </div>
              <div className='last'>
                <TextField
                  label="שם משפחה *"
                  variant="outlined"
                  {...register("last_name", {
                    required: { value: true, message: "שדה זה הוא שדה חובה" },
                    minLength: { value: 2, message: "אנא הכנס שני תווים לפחות" },
                    maxLength: { value: 50, message: "השם הפרטי ארוך מידי" }
                  })} />
                {!formState.isValid && <span className="error-message">{formState.errors.last_name?.message}</span>}
              </div>
              <div className='email'>
                <TextField
                  label="כתובת מייל *"
                  variant="outlined"
                  type="email"
                  {...register("email", {
                    required: { value: true, message: "שדה זה הוא שדה חובה" },
                    pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "כתובת מייל לא חוקית" }
                  })} />
                {!formState.isValid && <span className="error-message">{formState.errors.email?.message}</span>}
              </div>
              <div className='phone'>
                <TextField
                  label="מספר טלפון *"
                  variant="outlined"
                  type="tel"
                  onKeyUp={sanitize} onKeyDown={sanitize}
                  {...register("phone", {
                    required: { value: true, message: "שדה זה הוא שדה חובה" },
                    minLength: { value: 10, message: "מספר הטלפון חייב להיות 10 תווים" },
                    maxLength: { value: 10, message: "מספר הטלפון חייב להיות 10 תווים" }
                  })} />
                {!formState.isValid && <span className="error-message">{formState.errors.phone?.message}</span>}
              </div>
              {orderService.needsDelivery(orderType) && <>
                <div className='city'>
                  <Autocomplete
                    disablePortal
                    onChange={(e: any) => { const c = e.target?.value === 0 ? e.target?.textContent : e.target?.value; setSelectedCity(c ? c : ""); setValue("city", c ? c : ""); trigger("city"); }}
                    options={cities.map(c => c.שם_ישוב)}
                    renderInput={(params) => <TextField {...params} label="עיר *" />} />
                  {!formState.isValid && <span className="error-message">{formState.errors.city?.message}</span>}
                </div>
                <div className='street'>
                  {cityJustChanged ?
                    <TextField
                      disabled
                      variant="outlined"
                      value={"...טוען רחובות"} />
                    :
                    <Autocomplete
                      disabled={selectedCity === "" || !selectedCity}
                      disablePortal
                      onChange={(e: any) => { const s = e.target?.value === 0 ? e.target?.textContent : e.target?.value; setSelectedStreet(s); setValue("street", s ? s : ""); trigger("street"); }}
                      options={streets ? streets.map(c => c.שם_רחוב) : []}
                      renderInput={(params) => <TextField {...params} label="רחוב *" />} />
                  }
                  {!formState.isValid && <span className="error-message">{formState.errors.street?.message}</span>}
                </div>
                <div className='house'>
                  <TextField
                    label="מספר בית *"
                    type="tel"
                    variant="outlined"
                    onKeyUp={sanitize} onKeyDown={sanitize}
                    {...register("street_num", {
                      required: { value: true, message: "שדה זה הוא שדה חובה" },
                      pattern: { value: /^[0-9]+$/, message: "על שדה זה להכיל רק ספרות" }
                    })} />
                  {!formState.isValid && <span className="error-message">{formState.errors.street_num?.message}</span>}
                </div>
                <div className='zip'>
                  <TextField
                    label="מיקוד *"
                    type="tel"
                    variant="outlined"
                    onKeyUp={sanitize} onKeyDown={sanitize}
                    {...register("zip", {
                      required: { value: true, message: "שדה זה הוא שדה חובה" },
                      minLength: { value: 7, message: "המיקוד חייב להיות 7 תווים" },
                      maxLength: { value: 7, message: "המיקוד חייב להיות 7 תווים" }
                    })} />
                  {!formState.isValid && <span className="error-message">{formState.errors.zip?.message}</span>}
                </div>
                <input disabled hidden value={selectedCity} {...register("city", {
                  required: { value: true, message: "שדה זה הוא שדה חובה" }
                })} />
                <input disabled hidden value={selectedStreet} {...register("street", {
                  required: { value: true, message: "שדה זה הוא שדה חובה" }
                })} />
              </>}
              <div className='comments span-2'>
                <TextField
                  label="הערות"
                  variant="outlined"
                  multiline
                  {...register("comments")} />
                {!formState.isValid && <span className="error-message">{formState.errors.comments?.message}</span>}
              </div>
              <div className='coupon'>
                <TextField
                  label="קוד קופון"
                  variant="outlined" onChange={e => { e.target.value = e.target.value.toUpperCase().replace(/[^A-Z]/g, ''); setCouponCode(e.target.value); }} />
                <button type='button' disabled={couponCode === ""} onClick={fetchCoupon}>מימוש</button>
              </div>
              {orderService.needsDelivery(orderType) && <>
                <div className='amount'>
                  <FormControl>
                    <Select
                      labelId="amount-select-label"
                      defaultValue={1}
                      onChange={e => setValue("amount", +e.target.value)}
                      label="כמות">
                      {Array.from({ length: config.max_physical }, (_, i) => i + 1).map(n => <MenuItem value={n} key={n}>{n}</MenuItem>)}
                    </Select>
                    <InputLabel
                      className="amount-select-label"
                      style={{ position: "absolute", left: "0rem" }}>כמות</InputLabel>
                  </FormControl>
                </div>
                <div className='dedicate'>
                  <abbr title={amount > 1 ? "לא ניתן לכתוב הקדשה ליותר מספר אחד" : dedicate ? "לא!" : "כן!"}>
                    <Checkbox
                      disabled={amount > 1}
                      onChange={(e) => { const b = e.target?.checked; setDedicate(b); if (b) { setValue("dedication_name", forSelf ? getValues("first_name") : "no_need"); } else { setValue("dedication_name", "no_need") } trigger("dedication_name"); }} />
                  </abbr>
                  <label>הוסף הקדשה</label>
                  <br />
                  <Checkbox
                    defaultChecked
                    disabled={amount > 1}
                    className={dedicate ? "show" : "hide"}
                    onChange={(e) => setForSelf(e.target?.checked)} />
                  <label className={dedicate ? "show" : "hide"}>ההקדשה לנמען?</label>
                </div>
                <div className='dedication_name'>
                  <TextField
                    disabled={amount > 1}
                    className={!forSelf && dedicate ? "show" : "hide"}
                    label="?למי להקדיש"
                    variant="outlined"
                    defaultValue={!forSelf ? "no_need" : getValues("first_name")}
                    {...register("dedication_name", {
                      minLength: { value: 2, message: "אנא הכנס שני תווים לפחות" },
                      maxLength: { value: 50, message: "שם ההקדשה ארוך מידי" }
                    })} />
                  {!formState.isValid && <span className="error-message">{formState.errors.dedication_name?.message}</span>}
                </div>
                <span className='cost span-2'>עלות משלוח {coupon?.type === CouponType.shipping ? <b><s>₪{config.shipment_cost}</s> ₪{price.shipping}</b> : <b>₪{price.shipping}</b>}</span>
              </>
              }
              <span className='total'>סך הכל לתשלום {(coupon?.type === CouponType.percentDiscount || coupon?.type === CouponType.valueDiscount) ? <b><s>₪{price.original}</s> ₪{price.total}</b> : <b>₪{price.total}</b>}</span>
            </div>
            <br />
            <div className={`OrderButton ${!formState.isValid && formState.submitCount >= 1 ? "Invalid" : ""} ${isHidden ? "" : "Disabled"}`} >
              <button className={`${waitingForRes ? "vertical-center" : ""}`} disabled={!isHidden || disableButton}>{waitingForRes ? <img src={Scanner} alt="loading" /> : "בצע הזמנה"}</button>
              <span>בלחיצה על כפתור זה, אני מאשר/ת את שליחת הפרטים מעלה</span>
            </div>

            <div>
              <input disabled hidden type={"number"} value={amount} {...register("amount", { min: { value: 1, message: "הכמות לא יכולה להיות נמוכה מ-1" } })} />
              <input disabled hidden value={getValues("dedication_name")} />
            </div>
          </form >}
        {(!cities && orderService.needsDelivery(orderType)) && <Loading />}


        <div id="confirmation" className="confirmation" hidden={isHidden} ref={confirmationElement}>
          <h3>איזה כיף!!!</h3>
          <hr />
          <div className="content">
            <b>הזמנה מספר {orderNumber} התקבלה במערכת!</b>
            <p>
              מה שנותר כעת הוא לשלם.
              התשלום אצלנו עובד <a href='https://bitpay.page.link/?ibi=com.payments.paymentBit&apn=com.bnhp.payments.paymentsapp&link=https%3A//www.bitpay.co.il/app/bitcom-info'>בביט</a>, את הסכום יש להעביר למספר: {config.phone_number.local}
              <br />
              טרם אישור ההעברה, נציג מטעמנו יצור עמך קשר.
              <br />
              תודה רבה!

              {!orderService.needsDelivery(orderType) && <>
                <br />
                <br />
                <span>בשל עומס על המערכת, הקובץ ישלח אליך למייל עד 2 ימי עסקים.</span>
                <br />
                <span>ייתכן כי המייל יגיע אל "דואר הזבל" (ספאם)</span>
              </>}
            </p>
          </div>
        </div>
      </div >}
    </>
  );
}

export default DeliveryInfo;

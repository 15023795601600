import { useEffect, useState } from 'react';
import { BannerState } from '../../../Models/BannerState';
import configService from '../../../Services/configService';
import config from '../../../Utils/config';
import Router from '../../Routing/Router/Router';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import LoadingPage from '../LoadingPage/LoadingPage';
import PermaBanner from '../Perma-Banner/PermaBanner';
import './Layout.css';

function Layout() {
  const [show, setShow] = useState<boolean>(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [state, setState] = useState<BannerState>(BannerState.DeliveryDiscount);


  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleDarkModeChange = (event: any) => {
      setIsDarkMode(event.matches);
    };

    darkModeMediaQuery.addEventListener('change', handleDarkModeChange);
    setIsDarkMode(darkModeMediaQuery.matches);

    configService.getConfig().then(() => setShow(true));
    // config.updateConfig({
    //   "audio_discounted_amount": 999,
    //   "audio_price": 999,
    //   "banner_message": "לכבוד שבוע הספר - קופון למשלוח חינם!",
    //   "epub_discounted_amount": 37,
    //   "epub_price": 37,
    //   "max_physical": 5,
    //   "local_phone": "0527208555",
    //   "international_phone": "972527208555",
    //   "pdf_discounted_amount": 37,
    //   "pdf_price": 37,
    //   "physical_discounted_amount": 78,
    //   "physical_price": 78,
    //   "shipment_cost": 21,
    //   "shipment_cost_base": 21,
    //   "showBanner": true,
    // });
    // setShow(true)

    return () => {
      darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
    };
  }, []);


  useEffect(() => {
    if (!show || !config?.showBanner) {
      setState(BannerState.NoBanner);
      return;
    }

    if (config.shipment_cost === config.shipment_cost_base) {
      if (config.physical_discounted_amount < config.physical_price ||
        config.epub_discounted_amount < config.epub_price) {
        setState(BannerState.BookDiscount);
        return;
      }
      setState(BannerState.NoBanner);
      return;
    }
  }, [show]);

  return (
    <>
      <style>{`.Layout .Router {
    min-height: calc(100vh - var(--header-height) - (${state === BannerState.NoBanner ? 0 : 1} * var(--banner-height)) - var(--footer-height));
    padding-top: calc(var(--header-height) + (${state === BannerState.NoBanner ? 0 : 1}  * var(--banner-height)));
}`}</style>
      <div className={`Layout ${isDarkMode ? "dark" : ""}`}>
        <div className='stick'>
          <Header />
          <PermaBanner state={state} />
        </div>
        {show ?
          <Router /> :
          <LoadingPage />}
        <Footer />
      </div>
    </>
  );
}

export default Layout;

import Book3d from '../Book3d/Book3d';
import './Book3d2d.css';

interface Settings {
  /**
   * Rotation of the book, in degrees.
   */
  rotate: number
  /**
   * Rotation of the book on hover, in degrees.
   */
  rotateHover: number
  /**
   * Perspective value. 600 seems to be a realistic value.
   */
  perspective: number
  /**
   * Duration of rotate animation, in milliseconds.
   */
  transitionDuration: number
  /**
   * Radius of right corners, in pixels.
   */
  radius: number
  /**
   * Width of the book, in pixels.
   */
  width: number
}

interface Props extends Partial<Settings> {
  children: React.ReactNode
}

function Book3d2d({
  children,
  rotate,
  rotateHover,
  perspective,
  transitionDuration,
  radius = 0,
  width = 180
}: Props): JSX.Element {

  return (
    <Book3d
      rotate={rotate}
      radius={radius}
      rotateHover={rotateHover}
      perspective={perspective}
      transitionDuration={transitionDuration}
      width={width}
      thickness={0}
      pagesOffset={0}>{children}</Book3d>
  )
}

export default Book3d2d;

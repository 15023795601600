import ShortAbout from '../../AboutArea/ShortAbout/ShortAbout';
import Book from '../../BookArea/Book/Book';
import Cypher from '../../BookArea/Cypher/Cypher';
import Banner from '../../LayoutArea/Banner/Banner';
import StoreButton from '../../OrderArea/StoreButton/StoreButton';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <Banner />
      <Book />
      <Cypher />
      <ShortAbout />
      <StoreButton />
    </div>
  );
}

export default Home;

import { useEffect, useState } from 'react';
import OrderModel from '../../../Models/OrderModel';
import orderService from '../../../Services/orderService';
import './OrderList.css';

function OrderList() {
  const [orders, setOrders] = useState<OrderModel[]>([])
  const [editingOrder, setEditingOrder] = useState<OrderModel>(null);
  const [sortParam, setSortParam] = useState<string>();
  const [isInverted, setIsInverted] = useState<boolean>(false);
  const [detailsHidden, setDetailsHidden] = useState<boolean>(true);

  useEffect(() => {
    orderService.getAllOrders().then(o => setOrders(o?.sort((a, b) => (a.time > b.time ? 1 : -1))));
  }, [])

  function sortBy(param: keyof OrderModel) {
    if (editingOrder) return;
    const toInvert = param === sortParam ? !isInverted : false;
    setIsInverted(toInvert);
    setSortParam(param);
    const newOrders: OrderModel[] = JSON.parse(JSON.stringify(orders));
    newOrders.sort((a, b) => (a[param] > b[param] ? 1 : -1) * (toInvert ? -1 : 1));
    setOrders(newOrders)
  }

  function handleDoubleClick(order: OrderModel) {
    if (editingOrder !== null) return;
    if (!orderService.needsDelivery(order.type)) return;
    setEditingOrder(order);
  }

  function handleTrackingNumberChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const updatedOrder = { ...editingOrder };
    updatedOrder.tracking_number = ev.target.value;
    setEditingOrder(updatedOrder);
  }

  async function saveChanges() {
    const updatedOrder = await orderService.updateOrder(editingOrder);
    if (!updatedOrder) return;
    orderService.getAllOrders().then(o => setOrders(o.sort((a, b) => (a.time > b.time ? 1 : -1))));
    setEditingOrder(null);
  }

  return (
    <div className="OrderList">
      <h3>הזמנות</h3>
      <table>
        <thead>
          <tr>
            <th onClick={() => { sortBy("time") }}>תאריך{sortParam === "time" && (isInverted ? "v" : "^")}</th>
            <th onClick={() => { sortBy("order_number") }}>מספר הזמנה{sortParam === "order_number" && (isInverted ? "v" : "^")}</th>
            <th onClick={() => { sortBy("type") }}>סוג{sortParam === "type" && (isInverted ? "v" : "^")}</th>
            <th>שם מזמין</th>
            <th>טלפון</th>
            <th onClick={() => { sortBy("price") }}>סכום{sortParam === "price" && (isInverted ? "v" : "^")}</th>
            <th>מספר מעקב</th>
            <th onClick={() => { sortBy("amount") }}>כמות{sortParam === "amount" && (isInverted ? "v" : "^")}</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map(o => <tr onDoubleClick={() => { handleDoubleClick(o) }} key={o.order_number}>
            <td>{(new Date(o.time)).toLocaleDateString()}</td>
            <td>{o.order_number}</td>
            <td>{o.type}</td>
            <td>{o.first_name} {o.last_name}</td>
            <td>{o.phone}</td>
            <td>{o.price}</td>
            {orderService.needsDelivery(o.type)
              ?
              <td>{editingOrder?.order_number === o.order_number
                ?
                <input defaultValue={o.tracking_number} onChange={handleTrackingNumberChange} />
                :
                o.tracking_number}</td>
              :
              <td>XXXXXXXXX</td>}
            <td>{o.amount}</td>
          </tr>)}
        </tbody>
      </table>
      {editingOrder && <div>
        <button onClick={saveChanges}> שמור שינויים</button>
        <button onClick={() => { setEditingOrder(null) }}>בטל</button>
      </div>}
      <div className='details'>
        <button onClick={() => setDetailsHidden(b => !b)}>{detailsHidden ? "הצג" : "הסתר"} פרטים</button>
        {!detailsHidden &&
          <>
            <h4>כתובות</h4>
            <table>
              <thead>
                <tr>
                  <th>שם מזמין</th>
                  <th>עיר</th>
                  <th>רחוב</th>
                  <th>מספר בית</th>
                  <th>מיקוד</th>
                </tr>
              </thead>
              <tbody>
                {orders?.filter(o => orderService.needsDelivery(o.type)).map(o =>
                  <tr>
                    <td>{o.first_name} {o.last_name}</td>
                    <td>{o.city}</td>
                    <td>{o.street}</td>
                    <td>{o.street_num}</td>
                    <td>{o.zip}</td>
                  </tr>)}
              </tbody>
            </table>
            <h4>מיילים</h4>
            <table>
              <thead>
                <tr>
                  <th>שם מזמין</th>
                  <th>מייל</th>
                </tr>
              </thead>
              <tbody>
                {orders?.filter(o => !orderService.needsDelivery(o.type)).map(o =>
                  <tr>
                    <td>{o.first_name} {o.last_name}</td>
                    <td>{o.email}</td>
                  </tr>)}
              </tbody>
            </table>
          </>}
      </div>
    </div >
  );
}

export default OrderList;

import "./LoadingPage.css";
import spinner from "../../../Assets/Images/spinner.webp";
import { useEffect, useState } from "react";

function LoadingPage(): JSX.Element {
    const [isTimeUp, setIsTimeUp] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => setIsTimeUp(true), 3 * 60 * 1000)
    }, [])

    return (
        <div className="LoadingPage Router vertical-center">
            {isTimeUp && <div className="Text">אם הכיתוב הזה מוצג, כנראה שהאינטרנט שלך לא וואו<br />אני מנסה לייבא את הביטים ברצף הנכון (תמונות, טקסט, סרטונים [יש סרטונים באתר בכלל?]) כדי שהאתר יעבוד אז אל חשש, לא עשית שום דבר רע כדי לשבור את האתר.<br />אבל אם כבר הספקת לקרוא את מה שכתוב כאן, סימן שהאינטרנט שלך באמת איטי, או שמשהו ממש ממש גרוע קרה, אז אולי אפשר שתעדכן אותי?</div>}
            <img src={spinner} alt="loading" />
        </div>
    );
}

export default LoadingPage;

import './Banner.css';
import config from "../../../Utils/config";
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import dateService from '../../../Services/dateService';
import { BannerState } from '../../../Models/BannerState';


function Banner() {
  const [holiday, setHoliday] = useState<string>(null);
  const [state, setState] = useState<BannerState>(BannerState.NoBanner);

  useEffect(() => {
    if (!config.showBanner) return;

    if (config.banner_message !== "") {
      setState(BannerState.FreeText);
      return;
    }
    const nowHoliday = dateService.GetCurrentHoliday();
    if (nowHoliday) {
      setHoliday(nowHoliday);
      setState(BannerState.Holiday);
      return;
    }
    setState(BannerState.NoBanner);
  }, []);

  switch (state) {
    case BannerState.NoBanner:
      return <></>;
    case BannerState.Holiday:
      return (
        <NavLink to={"/store"} className="Banner">
          <div className='vertical-center'>
            <span>{holiday}</span>
          </div>
        </NavLink >
      );
    case BannerState.FreeText:
      return (
        <NavLink to={"/store"} className="Banner">
          <div className='vertical-center'>
            <span>{config.banner_message}</span>
          </div>
        </NavLink >
      );
  }
}

export default Banner;

import axios from 'axios';
import OrderModel from "../Models/OrderModel";
import { ProductType } from '../Models/productTypes';
import config from '../Utils/config';
import authService from './authService';
import notifyService from './NotifyService';

class OrderService {
    public async newOrder(order: OrderModel): Promise<OrderModel> {
        try {
            const res = await axios.post<OrderModel>(config.ordersEndpoint, order);
            if (res.status !== 201) {
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("orderService-newOrder");
            }
            notifyService.success("הזמנתך התקבלה במערכת!");
            return res.data;
        }
        catch (err: any) {
            notifyService.sendFailMessage(err as string);
        }
    }

    public getBasePrice(type: string): number {
        switch (type) {
            case ProductType.printedBook:
                return config.physical_discounted_amount;
            // case ProductType.audioBook:
            //     return config.audio_discounted_amount;
            case ProductType.ePub:
                return config.epub_discounted_amount;
            // case ProductType.pdf:
            //     return config.pdf_discounted_amount;
        }
    }

    public async getAllOrders(): Promise<null | OrderModel[]> {
        try {
            const res = await axios.get<OrderModel[]>(config.ordersEndpoint);
            if (res.status !== 200) {
                if (res.status === 401) authService.logout();
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("orderService-getAllOrders");
            }
            return res.data;
        }
        catch (err: any) {
            if (err?.response?.status === 401 || err?.status === 401) authService.logout();
            notifyService.sendFailMessage(err as string);
        }
    }

    public async searchOrder(phone_number: string): Promise<OrderModel> {
        try {
            const res = await axios.get<OrderModel>(`${config.ordersEndpoint}/search/${phone_number}`);
            if (res.status !== 200) {
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("orderService-getAllOrders");
            }
            return res.data;
        }
        catch (err: any) {
            notifyService.sendFailMessage(err as string);
        }
    }

    public async updateOrder(order: OrderModel): Promise<OrderModel> {
        try {
            const res = await axios.put<OrderModel>(`${config.ordersEndpoint}/${order.order_number}`, order);
            if (res.status !== 200) {
                if (res.status === 401) authService.logout();
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("orderService-newOrder");
            }
            notifyService.success("הזמנתך התקבלה במערכת!");
            return res.data;
        }
        catch (err: any) {
            if (err?.response.status === 401) authService.logout();
            notifyService.sendFailMessage(err as string);
        }
    }

    public needsDelivery(type: ProductType | string): boolean {
        if (type === ProductType.printedBook) {
            return true;
        }
        return false;
    }
}

const orderService = new OrderService();
export default orderService;
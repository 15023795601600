import './Login.css';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import CredentialsModel from '../../../../Models/CredentialsModel';
import authService from '../../../../Services/authService';
import notifyService from '../../../../Services/NotifyService';

function Login() {
  const { register, handleSubmit, formState } = useForm<CredentialsModel>();

  async function send(creds: CredentialsModel): Promise<void> {
    const token = await authService.login(creds);
    if (!token) return;
    notifyService.success("אם אתה לא אני אתה לא אמור להיות כאן")
    window.location.reload();
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit(send)}>
        <h2>כניסה</h2>
        <div>
          <TextField label="שם משתמש *" variant="outlined" {...register("username", {
            required: { value: true, message: "שדה זה הוא שדה חובה" },
            minLength: { value: 4, message: "אנא הכנס 4 תווים לפחות" },
            maxLength: { value: 100, message: "השם ארוך מידי" }
          })} />
          {!formState.isValid && <span className="error-message">{formState.errors.username?.message}</span>}
        </div>
        <div>
          <TextField label="סיסמה *" type="password" variant="outlined" {...register("password", {
            required: { value: true, message: "שדה זה הוא שדה חובה" },
            minLength: { value: 4, message: "אנא הכנס 4 תווים לפחות" },
            maxLength: { value: 100, message: "הסיסמה ארוכה מידי" }
          })} />
          {!formState.isValid && <span className="error-message">{formState.errors.password?.message}</span>}
        </div>
        <button>היכנס</button>
      </form>
    </div >
  );
}

export default Login;

import "./Loading.css";
import LoadingGif from "../../../Assets/Images/loading-gif.gif";

function Loading(): JSX.Element {
    return (
        <div className="Loading vertical-center">
			<img src={LoadingGif} alt="loading-gif" />
        </div>
    );
}

export default Loading;

import axios from 'axios';
import CredentialsModel from '../Models/CredentialsModel';
import config from '../Utils/config';
import notifyService from './NotifyService';
import { reduxStore } from "../Redux/ReduxStore";
import { login, logout } from '../Redux/Slices/authSlice';

class AuthService {
    public async login(creds: CredentialsModel): Promise<string> {
        try {
            const res = await axios.post<string>(config.authEndpoint + "/login", creds);
            if (res.status !== 200) {
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("authService-login");
            }
            notifyService.warn("אם אתה לא אני אתה לא אמור להיות פה");
            reduxStore.dispatch(login(res.data));
            return res.data;
        }
        catch (err) {
            notifyService.error(err);
        }
    }

    public logout(): void {
        reduxStore.dispatch(logout());
    }

    public getToken(): string {
        return "";
    }
}

const authService = new AuthService();
export default authService;
import './Logout.css';
import authService from '../../../../Services/authService';

function Logout() {

  return (
      <button className='Logout' onClick={authService.logout}>יציאה</button>
  );
}

export default Logout;

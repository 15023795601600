import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import authReducer, { AuthState } from "./Slices/authSlice";

export type StoreType = {
    auth: AuthState
}

export const reduxStore = configureStore({
    reducer: {
        auth: authReducer
    }
});

export const useAppDispatch: () => typeof reduxStore.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof reduxStore.getState>> = useSelector;
import { Navigate, Route, Routes } from "react-router-dom";
import About from "../../AboutArea/About/About";
import Home from "../../HomeArea/Home/Home";
import PageNotFound from "../PageNotFound/PageNotFound";
import "./Router.css";
import Store from "../../OrderArea/Store/Store";
import DeliveryInfo from "../../OrderArea/Delivery-Info/DeliveryInfo";
import ContactMe from "../../ContactArea/ContactMe/ContactMe";
import Admin from "../../AdminSuperArea/Admin";

function Router(): JSX.Element {
    return (
        <div className="Router">
            <Routes>
                <Route path="/home" element={<Home/>} />
                <Route path="/about" element={<About />} />
                <Route path="/store" element={<Store />} />
                <Route path="/order/:orderType" element={<DeliveryInfo />} />
                <Route path="/contact" element={<ContactMe />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    );
}
export default Router;

import './Cypher.css';
import word1_light from "../../../Assets/Images/sentence-2-word-1-light.png";
import word2_light from "../../../Assets/Images/sentence-2-word-2-light.png";
import word1_dark from "../../../Assets/Images/sentence-2-word-1-dark.png";
import word2_dark from "../../../Assets/Images/sentence-2-word-2-dark.png";
import { useEffect, useState } from 'react';

function Cypher() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleDarkModeChange = (event:any) => {
      setIsDarkMode(event.matches);
    };

    darkModeMediaQuery.addEventListener('change', handleDarkModeChange);
    setIsDarkMode(darkModeMediaQuery.matches);

    return () => {
      darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
    };
  }, []);

  function handleSizeChange() {
    // Do something about fucking landscape
    const mql = window.matchMedia("screen and (max-width:801px)")
    setIsMobile(mql.matches);
  }

  useEffect(() => {
    handleSizeChange();
    window.addEventListener('resize', handleSizeChange);
    return () => window.removeEventListener('resize', handleSizeChange);
  }, [])

  return (
    <div className="Cypher">
      {/* <img src={bg}></img> */}
      <div className="content">
        {!isMobile && <><h3 className='ltr'><span>ᚫ</span><span>ᛏᚫᛚᛖ</span><span>ᚦᚫᛏ</span><span>ᚲᚢᛖᛋᛏᛁᛟᚾ</span><span>ᚲᚢᛚᛏᚢᚱᛖᛋ</span></h3>
          <hr />
          <h3 className='ltr'><img src={isDarkMode ? word1_dark : word1_light} alt="sentence 2 word 1" /><img src={isDarkMode ? word2_dark : word2_light} alt="sentence 2 word 2" /><span>𓇌𓍯𓅱</span><span>𓎡𓋔𓍯𓅱</span></h3>
          <hr />
          <h3><span>𐤊𐤅𐤋𐤌</span><span>𐤌𐤀𐤌𐤉𐤍𐤉𐤌</span><span>𐤁𐤀𐤅𐤕𐤅</span><span>𐤄𐤃𐤁𐤓</span></h3>
          <hr />
          <h3 className='ltr'><span>𒉡𒋾𒅔𒄖</span><span>𒄑</span><span>𒋼𒊒</span></h3>
        </>}
        {isMobile && <>
          <h3 className='ltr'><span>ᚫ</span><span>ᛏᚫᛚᛖ</span><span>ᚦᚫᛏ</span></h3>
          <h3 className='ltr'><span>ᚲᚢᛖᛋᛏᛁᛟᚾ</span><span>ᚲᚢᛚᛏᚢᚱᛖᛋ</span></h3>
          <hr />
          <h3 className='ltr'><img src={isDarkMode ? word1_dark : word1_light} alt="sentence 2 word 1" /><img src={isDarkMode ? word2_dark : word2_light} alt="sentence 2 word 2" /></h3>
          <h3 className='ltr'><span>𓇌𓍯𓅱</span><span>𓎡𓋔𓍯𓅱</span></h3>
          <hr />
          <h3><span>𐤄𐤃𐤁𐤓</span><span>𐤁𐤀𐤅𐤕𐤅</span></h3>
          <h3><span>𐤌𐤀𐤌𐤉𐤍𐤉𐤌</span><span>𐤊𐤅𐤋𐤌</span></h3>
          <hr />
          <h3 className='ltr'><span>𒉡𒋾𒅔𒄖</span></h3>
          <h3 className='ltr'><span>𒄑</span><span>𒋼𒊒</span></h3>
        </>}
      </div>
    </div >
  );
}

export default Cypher;

import { useRef, useState } from 'react';
import './FirstChapter.css';
import page5 from "../../../Assets/Images/Pages/page5.webp";
import page6 from "../../../Assets/Images/Pages/page6.webp";
import page7 from "../../../Assets/Images/Pages/page7.webp";
import page8 from "../../../Assets/Images/Pages/page8.webp";
import page9 from "../../../Assets/Images/Pages/page9.webp";
import page10 from "../../../Assets/Images/Pages/page10.webp";
import page11 from "../../../Assets/Images/Pages/page11.webp";
import page12 from "../../../Assets/Images/Pages/page12.webp";
import page13 from "../../../Assets/Images/Pages/page13.webp";
import page14 from "../../../Assets/Images/Pages/page14.webp";
import page15 from "../../../Assets/Images/Pages/page15.webp";
import OnlyBook from '../OnlyBook/OnlyBook';


function FirstChapter() {
  const top = useRef<HTMLDivElement>();

  const PAGES = [page5, page6, page7, page8, page9, page10, page11, page12, page13, page14, page15]
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);

  const scrollToTop = () => {
    top?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  function handleClick(ev: any) {
    if (!ev.target) return;
    if (ev.target.localName === "img" || ev.target.localName === "span") {
      if (ev.pageX < window.innerWidth / 2) {
        if (page === PAGES.length - 1) return;
        setPage(p => p + 1);
        scrollToTop();
      }
      if (ev.pageX > window.innerWidth / 2) {
        if (page === 0) return;
        setPage(p => p - 1);
        scrollToTop();
      }
    }
    if (ev.target.localName === "div") {
      setIsHidden(true);
    }
  }
  return (
    <div className="FirstChapter">
      <div onClick={() => setIsHidden(!isHidden)}>
        <OnlyBook rotate={18} rotateHover={45} rotationRate={1} />
        <button>קרא חלק ראשון</button>
      </div>

      <div className='modal vertical-center' style={{ display: isHidden ? "none" : "flex" }} onClick={handleClick}>
        <div className='container vertical-center'>
          <div>
            {/* used for ScrollToTop */}
            <div ref={top} className="top"></div>
            {page > 0 && <span className='Back' onClick={handleClick}>&lt;</span>}
            <img alt={"Page number " + (page + 5)} src={PAGES[page]} />
            {page < PAGES.length - 1 && <span className='Forward' onClick={handleClick}>&gt;</span>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstChapter;

import './PageNotFound.css';
import what from '../../../Assets/Images/what-huh.gif';


function PageNotFound() {
  return (
    <div className="PageNotFound">
      <div className='content-container'>
        <h2>הדף אינו קיים. שגיאה 404</h2>
        <p>אני לא יודע מה אמור להיות מוצג פה. הדף שחיפשת הוא לא חלק מהאתר, אני גם לא יודע איך הגעת לכאן, אין אף קישור שמוביל לכאן.
          <br />
          הקלדת משהו לא נכון? או שהיצר ההרפתקני נכנס לפעולה?
          <br />
          היות ואף אחד לא מקליד כתובות אינטרנט יותר, אני אניח שזה הרצון לחפש עוד דברים, ואני מעריך את זה. אז אני אתמוך בכך ואתן לך את הרמז הבא:
          <br />
          <a href='https://www.royalmint.com/shop/coin-sets/2008-Elizabeth-II-Emblems-of-Britain-and-Royal-Shield-of-Arms-Gold-Proof-Set/'>United Kingdom 2008 Box Set</a>
          <br />
          אשמח למייל על הממצאים שלך לכתובת הבאה: <a href='mailto:books.by.oss@gmail.com?subject=404_Coins'>books.by.oss@gmail.com</a>
          <br />
          <br />
          ובלי קשר, גיף של טרבולטה.
        </p>
      </div>
      <div className='gif-container'>
        <img alt="what-huh" src={what} />
      </div>
    </div>
  );
}

export default PageNotFound;

type dateMonth = {
    day: number,
    month: number,
    secondaryMonth?: string
};

// Holiday month numbers are 1 indexed
type Holiday = {
    name: string,
    start: dateMonth,
    end: dateMonth,
    message: string
};

class DateService {
    private months: string[] = ["Tishri", "Heshvan", "Kislev", "Tevet", "Shevat", "Adar", "Nisan", "Iyar", "Sivan", "Tamuz", "Av", "Elul"];
    private hebHolidays: Holiday[] = [
        {
            name: "ראש השנה",
            start: { day: 26, month: 12 },
            end: { day: 2, month: 1 },
            message: "שנה טובה! 🍯🍎"
        },
        {
            name: "יום כיפור",
            start: { day: 5, month: 1 },
            end: { day: 10, month: 1 },
            message: "גמר חתימה טובה! 📖"
        },
        {
            name: "סוכות",
            start: { day: 13, month: 1 },
            end: { day: 21, month: 1 },
            message: "סוכות שמח! 🍋🌿"
        },
        {
            name: "יום זיכרון חרבות ברזל",
            start: { day: 22, month: 1 },
            end: { day: 24, month: 1 },
            message: "יזכור🕯️🎗️"
        },
        {
            name: "חנוכה",
            start: { day: 19, month: 3 },
            end: { day: 3, month: 4 },
            message: "חנוכה שמח! 🕎"
        },
        {
            name: "פורים",
            start: { day: 9, month: 6 },
            end: { day: 15, month: 6 },
            message: "פורים שמח! 🎭"
        },
        {
            name: "פסח",
            start: { day: 9, month: 7 },
            end: { day: 21, month: 7 },
            message: "פסח שמח! 🐫𓀾🐫𓀾"
        },
        {
            name: "ימי זיכרון",
            start: { day: 27, month: 7 },
            end: { day: 4, month: 8 },
            message: "יזכור🕯️"
        },
        {
            name: "יום העצמאות",
            start: { day: 5, month: 8 },
            end: { day: 6, month: 8 },
            message: "יום עצמאות שמח! 🇮🇱"
        },
        {
            name: "שבועות",
            start: { day: 1, month: 9 },
            end: { day: 7, month: 9 },
            message: "חג שבועות שמח! 🧀🌾"
        },
        {
            name: "טו באב",
            start: { day: 14, month: 11 },
            end: { day: 15, month: 11 },
            message: "יום אהבה שמח! 💘"
        }
    ];
    private latHolidays: Holiday[] = [
        {
            name: "שנה חדשה",
            start: { day: 31, month: 12 },
            end: { day: 1, month: 1 },
            message: "שנה אזרחית טובה! 🎆"
        },
        {
            name: "יום",
            start: { day: 19, month: 1 },
            end: { day: 19, month: 1 },
            message: "🥳"
        },
        {
            name: "ולנטיין",
            start: { day: 14, month: 2 },
            end: { day: 14, month: 2 },
            message: "וולנטיין שמח! 💘"
        },
        {
            name: "יום פאי",
            start: { day: 14, month: 3 },
            end: { day: 14, month: 3 },
            message: "...3.141592653589793"
        },
        {
            name: "יום השיוויון",
            start: { day: 20, month: 3 },
            end: { day: 20, month: 3 },
            message: "יום שיוויון שמח! ☀️🌕"
        },
        {
            name: "אחד באפריל",
            start: { day: 1, month: 4 },
            end: { day: 1, month: 4 },
            message: "אחד באפריל! 😋"
        },
        {
            name: "מלחמת הכוכבים",
            start: { day: 4, month: 5 },
            end: { day: 4, month: 5 },
            message: "May the fourth be with you! 🌌"
        },
        {
            name: "יום המגבת",
            start: { day: 25, month: 5 },
            end: { day: 25, month: 5 },
            message: "מגבת- הדבר השימושי ביותר לטרמפיסט בין-כוכבי🚀"
        },
        {
            name: "היום הארוך בשנה",
            start: { day: 21, month: 6 },
            end: { day: 21, month: 6 },
            message: "היום הוא היום הארוך בשנה! ☀️"
        },
        {
            name: "יום השיוויון",
            start: { day: 22, month: 9 },
            end: { day: 22, month: 9 },
            message: "יום שיוויון שמח! ☀️🌕"
        },
        {
            name: "היום קצר בשנה",
            start: { day: 21, month: 12 },
            end: { day: 21, month: 12 },
            message: "היום הוא היום הקצר בשנה! 🌕"
        },
    ]

    private compareDates(date: dateMonth, holidayArr: Holiday[]): string {
        for (const h of holidayArr) {
            if (h.name === "פורים" && date?.secondaryMonth === "I") {
                return null;
            }

            // spaning more than a month and we are in the month between
            if (date.month > h.start.month && date.month < h.end.month) {
                return h.message;
            }
            // spaning less than a month and not crossing months
            if (h.start.month === h.end.month && h.start.month === date.month) {
                if (h.start.day <= date.day && h.end.day >= date.day) {
                    return h.message;
                }
            }
            // spaning less than a month and crossing months. start
            if (h.start.month < h.end.month && h.start.month === date.month) {
                if (date.day >= h.start.day) {
                    return h.message;
                }
            }
            // spaning less than a month and crossing months. end
            if (h.start.month < h.end.month && h.end.month === date.month) {
                if (date.day <= h.end.day) {
                    return h.message;
                }
            }
            if (h.start.month > h.end.month) {
                if (date.month === 1 && h.end.month === 1 && date.day <= h.end.day) {
                    return h.message;
                }
                if (date.month === 12 && h.start.month === 12 && date.day >= h.start.day) {
                    return h.message;
                }
            }
        }
        return null;
    }

    public GetCurrentHoliday(): string {
        // [day, month, year]
        // ["15", "Tishri", "5784"]
        const stringDate: string = (new Intl.DateTimeFormat('en-u-ca-hebrew', { dateStyle: "short" }).format(new Date()));
        const heDateArr: string[] = stringDate.split(" ");
        const hebDate: dateMonth = { day: +heDateArr[0], month: this.months.indexOf(heDateArr[1]) + 1, secondaryMonth: heDateArr.length === 4 ? heDateArr[2] : undefined};
        const hebHolidayMessage = this.compareDates(hebDate, this.hebHolidays);

        const now = new Date();
        const latDate: dateMonth = {day: now.getDate(), month: now.getMonth() + 1};
        const latHolidayMessage = this.compareDates(latDate, this.latHolidays);

        if (hebHolidayMessage  && !latHolidayMessage) return hebHolidayMessage;
        if (!hebHolidayMessage &&  latHolidayMessage) return latHolidayMessage;
        if (hebHolidayMessage  &&  latHolidayMessage) return hebHolidayMessage;
        return null;
    }
}

const dateService = new DateService();
export default dateService;
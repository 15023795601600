import { useSelector } from 'react-redux';
import { StoreType } from '../../Redux/ReduxStore';
import './Admin.css';
import Login from './AuthArea/Login/Login';
import Logout from './AuthArea/Logout/Logout';
import ConfigEdit from './ConfigArea/ConfigEdit';
import CouponList from './CouponArea/CouponList';
import OrderList from './OrderArea/OrderList';

function Admin() {
  const token = useSelector<StoreType, string>(state => state.auth.token);

  return (
    <div className="Admin">
      {!token && <Login />}
      {token && <>
        <OrderList />
        <CouponList />
        <ConfigEdit />
        <br />
        <br />
        <Logout />
      </>}
    </div >
  );
}

export default Admin;

import './About.css';
import rose from "../../../Assets/Images/rose.png";
import lavender from "../../../Assets/Images/lavender.png";

function About() {
  return (
    <div className="About">
      <div>
        <h2>קצת יותר עליי</h2>
        <div className='AboutMe'>
          <div className='content'>
            <p>את הרעיון לספר קיבלתי קצת לפני הצבא, אבל לא ידעתי מה לעשות עם הרעיון הזה. איך מתחילים לכתוב ספר?
              <br />
              מאז ומעולם אהבתי לקרוא, אבל אחרי ההחלטה ליישם את הרעיון התחלתי לקרוא אפילו יותר ספרים, ניסיתי לשאול לעצתם של מספר סופרים וחקרתי על סיפורי פולקלור רבים. ידעתי איך אני רוצה שהסיפור יתחיל ואיך יסתיים, כל מה שבין לבין היה רק חלקי מידע.
              <br />
              ככלל אני יכול להגיד שאני אוהב לכתוב, גם תוכנה וגם סיפורים, לתאר מקומות שמעולם לא הייתי בהם ולפתור פתרונות שמעולם לא נתקלתי בהם קודם לכן.
              <br />
              נראה לי שחפרתי ואני גם ככה לא מאוד אוהב לדבר על עצמי 😅, אז אשאיר לספר שלי לעשות את שאר הדיבורים.
              <br />
              מקווה שתהנו!</p>
            <div className='vertical-center'>
              <img src={lavender} alt="lavender"/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>קצת יותר על הספר</h2>
        <div className='bookLengthy'>
          <div></div>
          <div className="content">
            <div className='vertical-center'>
              <img src={rose} alt="rose"/>
            </div>
            <p >"שדים ומלאכים" הוא תוצר של הרבה מחשבות ורעיונות שהצטברו לאורך השנים. הוא התחיל כרעיון מעומעם אשר עם הזמן סיפח אליו עוד ועוד תוכן מכל מיני משחקים ששיחקתי, סדרות וסרטים שראיתי ואף שירים ששמעתי. הוא מספר את סיפורו של לוקאס מילר, שנגרר אל תוך עולם מיסטי ונאלץ להתמודד עם השאלות הפילוסופיות הקשות ביותר, תוך כדי שהוא מנסה להבין את העולם החדש שנגלה אליו.
              <br />
              לאורך הספר, ניסיתי לשלב כמה שיותר מידע ממיתולוגיות וסיפורי עם מתרבויות שונות; חלקן מוכרות יותר כמו הסיפורים על אל הברק והרעם - ת'ור מהמיתולוגיה הנורדית, וחלקן מוכרות פחות כמו סיפור המלחמה הזורואסטרי בין אהורה-מאזדה יוצר היקום לבין אהרימן התגלמות הרשע. אבל כמובן גם שלצד סיפורי הגבורה הללו, ישנם גם סיפורים... לא נעימים (אין מה לעשות, גהינום הוא לא תמיד מקום יפה).
              <br />
              מקווה שתהנו מהקריאה!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

import { useState } from 'react';
import { Control, Controller, FormState, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import CouponConditions, { Condition } from '../../../Models/ConditionModel';
import CouponModel from '../../../Models/CouponModel';
import OrderModel from '../../../Models/OrderModel';
import { ProductType } from '../../../Models/productTypes';
import './ConditionList.css';

interface ConditionListParams {
  formState: FormState<CouponModel>,
  control?: Control<CouponModel, any>,
  getValues: UseFormGetValues<CouponModel>,
  setValue: UseFormSetValue<CouponModel>,
  watch: UseFormWatch<CouponModel>,
  conditions: CouponConditions<OrderModel>[],
  selected?: boolean,
  code: string
}

function ConditionList({ formState, setValue, getValues, watch, conditions, control, selected, code }: ConditionListParams): JSX.Element {
  const [conditionsArr, setConditionsArr] = useState<CouponConditions<OrderModel>[]>(conditions);

  function handleAdd() {
    setConditionsArr([...conditionsArr, new CouponConditions<OrderModel>()])
  }

  function deleteCond(index: number) {
    if (window.confirm(`למחוק את תנאי מספר ${index + 1}?`)) {
      const currConditions = [...getValues().conditions.filter(c => c !== undefined)]
      currConditions.splice(index, 1);
      setConditionsArr(currConditions)
      for (let i = 0; i < currConditions.length; i++) {
        setValue(`conditions.${i}.field`, currConditions[i].field);
        setValue(`conditions.${i}.condition`, currConditions[i].condition);
        setValue(`conditions.${i}.value`, currConditions[i].value);
      }
    }
  }

  return (
    <div className="ConditionList">
      <table>
        <thead>
          <tr>
            <th>שדה</th>
            <th>תנאי</th>
            <th>ערך</th>
            <th>מחק</th>
          </tr>
        </thead>
        <tbody>
          {conditionsArr.map((cond, i: number) => {
            return selected ?
              <tr key={code + i}>
                <td key={code + "fieldTd" + i}>
                  <Controller
                    name={`conditions.${i}.field`}
                    control={control}
                    key={code + "fieldControl" + i}
                    defaultValue={cond.field}
                    rules={{ required: { value: true, message: "תנאי קופון חייב שדה" } }}
                    render={({ field: { onChange, value, ref } }) => (
                      <select disabled={!selected} key={code + "field" + i} value={value} ref={ref} onChange={onChange} className={formState?.errors?.conditions && formState?.errors?.conditions[i]?.field !== undefined ? "error-field" : ""}>
                        <option key={code + "field" + i + "option"} disabled selected>select field</option>
                        {Object.keys(OrderModel.GetDefaultObject()).map(t => <option key={code + t + i} value={t}>{t}</option>)}
                      </select>
                    )} />
                </td>
                <td key={code + "conditionTd" + i}>
                  <Controller
                    name={`conditions.${i}.condition`}
                    control={control}
                    key={code + "conditionControl" + i}
                    defaultValue={cond.condition}
                    rules={{ required: { value: true, message: "תנאי קופון חייב תנאי" } }}
                    render={({ field: { onChange, value, ref } }) => (
                      <select disabled={!selected} key={code + "condition" + i} value={value} ref={ref} onChange={onChange} className={formState?.errors?.conditions && formState?.errors?.conditions[i]?.condition !== undefined ? "error-field" : ""}>
                        <option key={code + "condition" + i + "option"} disabled selected>select cond</option>
                        {Object.values(Condition).map(c => <option key={code + c + i} value={c}>{Object.entries(Condition).find(([key, val]) => val === c)?.[0]}</option>)}
                      </select>
                    )} />
                </td>
                <td key={code + "valueTd" + i}>
                  <Controller
                    name={`conditions.${i}.value`}
                    control={control}
                    key={code + "valueControl" + i}
                    defaultValue={cond.value}
                    rules={{ required: { value: true, message: "תנאי קופון חייב ערך" } }}
                    render={({ field: { onChange, value, ref } }) => {
                      const field = watch(`conditions.${i}.field`);
                      if (field === "type") {
                        return <select disabled={!selected} key={code + "value" + i} value={value} ref={ref} onChange={onChange} className={formState?.errors?.conditions && formState?.errors?.conditions[i]?.condition !== undefined ? "error-field" : ""}>
                          <option key={code + "value" + i + "option"} disabled selected>select type</option>
                          {Object.values(ProductType).map(t => <option key={code + t + i} value={t}>{Object.entries(ProductType).find(([key, val]) => val === t)?.[0]}</option>)}
                        </select>
                      }
                      return <input key={code + "value" + i} type={OrderModel.GetMemberType(field)} value={value} ref={ref} onChange={onChange} className={formState?.errors?.conditions && formState?.errors?.conditions[i]?.value !== undefined ? "error-field" : ""} />
                    }} />
                </td>
                <td key={code + "buttonTd" + i}><button key={code + "delete" + i} type="button" onClick={() => deleteCond(i)}>X</button></td>
              </tr>
              :
              <tr key={code + i}>
                <td key={code + "fieldTd" + i}>{cond.field}</td>
                <td key={code + "conditionTd" + i}>{cond.condition}</td>
                <td key={code + "valueTd" + i}>{cond.value}</td>
                <td key={code + "deleteTd" + i}><button key={code + "delete" + i} type="button" disabled>X</button></td>
              </tr>
          }
          )}
        </tbody>
      </table>
      {selected && <button type="button" onClick={handleAdd}>+</button>}
    </div>
  );
}

export default ConditionList;

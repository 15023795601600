import axios from 'axios';
import CouponModel from '../Models/CouponModel';
import config from '../Utils/config';
import authService from './authService';
import notifyService from './NotifyService';
import { JSEncrypt } from 'jsencrypt';
import OrderModel from '../Models/OrderModel';

class CouponService {
    public async newCoupon(coupon: CouponModel): Promise<CouponModel> {
        try {
            const res = await axios.post<CouponModel>(config.couponsEndpoint, coupon);
            if (res.status !== 201) {
                if (res.status === 401) authService.logout();
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("couponService-newCoupon");
            }
            notifyService.success("התווסף");
            return res.data;
        }
        catch (err: any) {
            notifyService.sendFailMessage(err as string);
        }
    }

    public async getAllCoupons(privateKey: string): Promise<CouponModel[]> {
        try {
            const res = await axios.get<CouponModel[]>(config.couponsEndpoint);
            if (res.status !== 200) {
                if (res.status === 401) authService.logout();
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("couponService-getAllCoupons");
            }
            return res.data.map(c => {
                c.code = this.rsaDecrypt(c.code, privateKey);
                if (c.code === null) {
                    throw new Error("couponService-rsaDecrypt")
                }
                return c;
            });
        }
        catch (err: any) {
            if (err?.response.status === 401) authService.logout();
            notifyService.sendFailMessage(err as string);
        }
    }

    public async updateCoupon(coupon: CouponModel): Promise<CouponModel> {
        try {
            const res = await axios.put<CouponModel>(`${config.couponsEndpoint}/${coupon.id}`, coupon);
            if (res.status !== 200) {
                if (res.status === 401) authService.logout();
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("couponService-newOrder");
            }
            notifyService.success("עודכן");
            return res.data;
        }
        catch (err: any) {
            if (err?.response.status === 401) authService.logout();
            notifyService.sendFailMessage(err as string);
        }
    }

    public async deleteCoupon(id: string): Promise<boolean> {
        try {
            const res = await axios.delete(`${config.couponsEndpoint}/${id}`)
            if (res.status !== 204) {
                if (res.status === 401) authService.logout();
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("couponService-newOrder");
            }
            notifyService.success("הקופון נמחק");
            return true;
        }
        catch (err: any) {
            if (err?.response.status === 401) authService.logout();
            notifyService.sendFailMessage(err as string);
        }
        return false
    }

    public async getCoupon(code: string, orderState: OrderModel): Promise<CouponModel> {
        try {
            const res = await axios.post<CouponModel>(`${config.couponsEndpoint}/${code}`, orderState);
            if (res.status !== 200) {
                notifyService.error("קוד הקופון אינו קיים");
                throw new Error("couponService-getCoupon");
            }
            return res.data;
        }
        catch (err: any) {
            notifyService.sendFailMessage(err as string);
        }
    }


    private rsaDecrypt(cipherText: string, key: string): string {
        if (!cipherText) return null;

        const decrypt = new JSEncrypt()
        decrypt.setPrivateKey(key);
        const plainText = decrypt.decrypt(cipherText);
        if (typeof plainText === "boolean" || plainText === null) {
            return null;
        }
        return plainText;
    }
}

const couponService = new CouponService();
export default couponService;
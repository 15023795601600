import './Book.css';
import FirstChapter from '../FirstChapter/FirstChapter';

function Book() {
  return (
    <div className="Book">
      <div className="container margin-top-2 vertical-center">
        <FirstChapter />
      </div>
      {/* <div className='filler'></div> */}
      <div className='Summary vertical-center' id='summary'>
        <div>
          "אם הייתה לכם אפשרות להרוג את האדם הכי רשע בעולם, הייתם עושים את זה?"
          <br />
          <br />
          לוקאס מילר מאז ומתמיד היה ילד נורמלי, לפחות עד שקיבל בדרך מסתורית את היכולת לראות שדים ומלאכים. כעת, שני הצדדים מנסים לנצל אותו לטובתם והוא קרוע בין שני העולמות, מנסה להתמודד מול שדים מרובי ראשים, מלאכים מרובי כנפיים, ושאלה אחת.
          <br />
          <br />
          "שדים ומלאכים" הוא סיפור מותח, סוחף ולעיתים מבעית שמשלב הרפתקאות, רומנטיקה, מוסר, וגיבורים מכל קצוות תבל.
          <br />
          <hr />
        </div>
      </div>
    </div>
  );
}

export default Book;

import { NavLink } from 'react-router-dom';
import './StoreButton.css';


function StoreButton() {
  return (
    <div className="StoreButton">
      <NavLink to="/store">
        <button>לרכישה!</button>
      </NavLink>
    </div>
  );
}

export default StoreButton;

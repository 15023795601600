import { createSlice } from "@reduxjs/toolkit";

const storageKey = "theCurtain";

export type AuthState = { token: string };

const initialState: AuthState = {
    token: sessionStorage.getItem(storageKey) || ""
}

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload;
            sessionStorage.setItem(storageKey, action.payload);
        },
        logout: (state) => {
            sessionStorage.removeItem(storageKey);
            state.token = null;
        }
    }
})

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
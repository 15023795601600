import axios from "axios";
import { reduxStore } from "../Redux/ReduxStore";

class InterceptorService {
    public createInterceptor() {
        // injecting each and every sent request with the user token
        axios.interceptors.request.use((request) => {
            if (reduxStore.getState().auth.token && request.url.indexOf("https://data.gov.il") !== 0){
                request.headers.setAuthorization(`Bearer ${reduxStore.getState().auth.token}`)
            }
            return request;
        });
    }
}

const interceptorService = new InterceptorService();
export default interceptorService;
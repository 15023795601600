import './Header.css';
import { NavLink } from 'react-router-dom';

function Header() {

  return (
    <div className="Header vertical-center">
      <div className='container'>
        <NavLink to={"/home"}>בית</NavLink>
        <NavLink to={"/store"}>לרכישה</NavLink>
        <NavLink to={"/about"}>אודות</NavLink>
        <NavLink to={"/contact"}>צור קשר</NavLink>
      </div>
    </div>
  );
}

export default Header;

import './Footer.css';

function Footer() {
  return (
    <div className="Footer vertical-center">
      <div className="super-container">
        <div className="container">
          <span>כל הזכויות שמורות</span><span className='copyright'>Ⓒ</span><span>All rights reserved</span>
        </div>
        <span>{(new Date()).getFullYear()}</span>
        <br />
        <span className='emoji'>😈😇🧄</span>
      </div>
    </div>
  );
}

export default Footer;

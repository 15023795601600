import axios, { AxiosResponse } from 'axios';
import { RawAddressModel, AddressModel } from '../Models/AddressModel';
import config from '../Utils/config';
import notifyService from './NotifyService';

class AddressService {
    public async getAllCites(): Promise<AddressModel[]> {
        try {
            const response: AxiosResponse<RawAddressModel> = await axios.get<RawAddressModel>(config.citiesAPI);
            response.data.result.records.map(c => {
                if (c.שם_ישוב.indexOf(")") >= 0) {
                    c.שם_ישוב = c.שם_ישוב.substring(0, c.שם_ישוב.indexOf(")")).trim();
                }
                c.שם_ישוב = c.שם_ישוב.trim();
                return c;
            })
            const uniques = response.data.result.records.filter((val, i, arr) => i === arr.findIndex((t) => (t.שם_ישוב === val.שם_ישוב)));
            return uniques;
        }
        catch (error: any) {
            notifyService.sendFailMessage(error as string);
            console.error(error);
        }
    }

    public async getStreetByCity(city: string): Promise<AddressModel[]> {
        try {
            const response: AxiosResponse<RawAddressModel> = await axios.post<RawAddressModel>(config.streetsAPI + `&q=${city}`);
            response.data.result.records.map(s => {
                if (s.שם_רחוב.indexOf("(") >= 0) {
                    s.שם_רחוב = s.שם_רחוב.substring(0, s.שם_רחוב.indexOf("(")).trim();
                }
                s.שם_רחוב = s.שם_רחוב.trim();
                return s;
            })
            const uniques = response.data.result.records.filter((val, i, arr) => i === arr.findIndex((t) => (t.שם_רחוב === val.שם_רחוב)));
            return uniques;
        }
        catch (error: any) {
            notifyService.sendFailMessage(error as string);
            console.error(error);
        }
    }
}

const addressService = new AddressService();
export default addressService;
import { ProductType } from "./productTypes";

class OrderModel {
    public order_number: string;
    public type: ProductType;
    public time: number;

    public first_name: string;
    public last_name: string;

    public amount: number;

    public phone: string;

    public street: string;
    public street_num: number;
    public city: string;
    public zip: string;

    public email: string;

    public comments: string;

    public price: number;

    public dedication_name: string;

    public tracking_number: string;

    public coupon_code: string;

    private static obj: OrderModel = {
        order_number: "",
        type: ProductType.printedBook,
        time: 0,
        first_name: "",
        last_name: "",
        amount: 0,
        phone: "",
        street: "",
        street_num: 0,
        city: "",
        zip: "",
        email: "",
        comments: "",
        price: 0,
        dedication_name: "",
        tracking_number: "",
        coupon_code: ""
    }
    
    public static GetDefaultObject() {
        return OrderModel.obj;
    }

    public static GetMemberType(m: keyof OrderModel): string {
        return typeof OrderModel.GetDefaultObject()[m];
    }
}

export default OrderModel;
export enum Condition {
    is = "is",
    greaterThan = "gt",
    greaterOrEqualTo = "gte",
}

export default class CouponConditions<T> {
    public field: keyof T;
    public condition: Condition;
    public value: T[keyof T];
}
import './ContactMe.css';
import ig_logo from "../../../Assets/Images/ig_logo.png";
import spotify_logo from "../../../Assets/Images/spotify_logo.png";
// import whatsapp_logo from "../../../Assets/Images/whatsapp_logo.png";
import contactService from '../../../Services/contactService';
import ContactModel from '../../../Models/ContactModel';
// import config from '../../../Utils/config';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

function ContactMe() {
  const { register, handleSubmit, formState, reset } = useForm<ContactModel>();

  async function send(contact: ContactModel): Promise<void> {
    const new_message = await contactService.sendMessage(contact);
    if (new_message) reset();
  }

  return (
    <div className="ContactMe">
      <form onSubmit={handleSubmit(send)}>
        <h2>אשמח לשמוע מכם!</h2>
        <div className='form-container'>
          <div>
            <TextField label="שם מלא *" variant="outlined" {...register("from_name", {
              required: { value: true, message: "שדה זה הוא שדה חובה" },
              minLength: { value: 2, message: "אנא הכנס שני תווים לפחות" },
              maxLength: { value: 50, message: "השם ארוך מידי" }
            })} />
            {!formState.isValid && <span className="error-message">{formState.errors.from_name?.message}</span>}
          </div>
          <div>
            <TextField label="כותרת *" variant="outlined" {...register("subject", {
              required: { value: true, message: "שדה זה הוא שדה חובה" },
              minLength: { value: 2, message: "אנא הכנס שני תווים לפחות" },
              maxLength: { value: 50, message: "הכותרת ארוכה מידי" }
            })} />
            {!formState.isValid && <span className="error-message">{formState.errors.subject?.message}</span>}
          </div>
          <div>
            <TextField label="כתובת מייל *" type="email" variant="outlined" {...register("reply_to", {
              required: { value: true, message: "שדה זה הוא שדה חובה" }
            })} />
            {!formState.isValid && <span className="error-message">{formState.errors.reply_to?.message}</span>}
          </div>
          <div className='span-2'>
            <TextField label="הודעה *" variant="outlined" multiline maxRows={4} {...register("message", {
              required: { value: true, message: "שדה זה הוא שדה חובה" }
            })} />
            {!formState.isValid && <span className="error-message">{formState.errors.message?.message}</span>}
          </div>
        </div>
        <button>שלח</button>
      </form>
      {/* <p>כדרך אגב... אתם תמיד מוזמנים גם ליצור קשר ברשתות החברתיות, או דרך המייל שלי</p>
      <p>books.by.oss@gmail.com</p> */}
      <div className='container'>
        <a href='https://instagram.com/books.by.oss/'><img alt="instagram" src={ig_logo} /></a>
        <a href='https://open.spotify.com/episode/20Xr55mL4JtNnn6Ss6ScXV?si=TzW36vn4TJqG2R1DOJd6Cg'><img alt="spotify" src={spotify_logo} /></a>
        {/* <a href={`https://api.whatsapp.com/send?phone=${config.phone_number.international}?text=היי%20ראיתי%20את%20הספר%20שלך%20ורציתי%20לדבר`}> <img alt="whatsapp" src={whatsapp_logo} /></a> */}
      </div>
    </div >
  );
}

export default ContactMe;

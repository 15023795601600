import CouponConditions from "./ConditionModel";
import OrderModel from "./OrderModel";

export enum CouponType {
    percentDiscount = "percentDiscount",
    valueDiscount = "valueDiscount",
    item = "item",
    shipping = "shipping"
}

class CouponModel {
    public id: string;
    public type: CouponType;
    public code: string;
    public discount: number;
    public conditions: CouponConditions<OrderModel>[];
}

export default CouponModel;
import { NavLink } from 'react-router-dom';
import './ShortAbout.css';
import myself from "../../../Assets/Images/Myself.webp"


function ShortAbout() {
  return (
    <div className="ShortAbout">
      <h2>בקצרה עליי</h2>
      <div className="content">
        <p>היי, אני עוז.
          <br />
          מאוד מתרגש לשתף איתכם את הספר שלי אחרי יותר משנתיים של עבודה.
          <br />
          אם אתם רוצים לקרוא עליי קצת יותר, מוזמנים ללחוץ <NavLink to="/about">ממש כאן</NavLink></p>
        <img src={myself} alt="Oz"/>
      </div>
    </div>
  );
}

export default ShortAbout;

import './PermaBanner.css';
import config from "../../../Utils/config";
import { NavLink } from 'react-router-dom';
import { BannerState } from '../../../Models/BannerState';


function PermaBanner(props: { state: BannerState }) {
  switch (props.state) {
    case BannerState.NoBanner:
      return <></>;
    case BannerState.DeliveryDiscount:
      return (
        <NavLink to={"/store"} className="PermaBanner">
          <div className='vertical-center'>
            <span>לזמן מוגבל - משלוח ב-{config.shipment_cost} ש"ח בלבד!</span>
          </div>
        </NavLink >
      );
    case BannerState.BookDiscount:
      return (
        <NavLink to={"/store"} className="PermaBanner">
          <div className='vertical-center'>
            <span>יש הנחה מטורפת בחנות! 😘</span>
          </div>
        </NavLink >
      );
  }
}

export default PermaBanner;

import axios from 'axios';
import ContactModel from "../Models/ContactModel";
import config from '../Utils/config';
import notifyService from './NotifyService';

class ContactService {
    public async sendMessage(contact: ContactModel): Promise<ContactModel> {
        try {
            const res = await axios.post<ContactModel>(config.contactEndpoint, contact);
            if (res.status !== 201) {
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("contactService-sendMessage");
            }
            notifyService.success("אל חשש, ההודעה נשלחה");
            return res.data;
        }
        catch(err) {
            notifyService.sendFailMessage(err as string);
        }
    }
}

const contactService = new ContactService();
export default contactService;
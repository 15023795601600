import axios from 'axios';
import FrontendConfig from "../Models/FrontendConfig";
import { ProductType } from '../Models/productTypes';
import config from '../Utils/config';
import authService from './authService';
import notifyService from './NotifyService';

class ConfigService {
    private config = {};
    public async getConfig(): Promise<FrontendConfig> {
        try {
            const res = await axios.get<FrontendConfig>(config.configEndpoint);
            if (res.status !== 200) {
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("configService-getConfig");
            }
            config.updateConfig(res.data);
            return res.data;
        }
        catch (err: any) {
            if (err?.response?.status === 401 || err?.status === 401) authService.logout();
            notifyService.sendFailMessage(err as string);
        }
    }

    public async updateConfig(conf: FrontendConfig): Promise<FrontendConfig> {
        try {
            const res = await axios.put<FrontendConfig>(config.configEndpoint, conf);
            if (res.status !== 200) {
                if (res.status === 401) authService.logout();
                notifyService.sendFailMessage(res.data.toString());
                throw new Error("configService-updateConfig");
            }
            notifyService.success("נשמר");
            config.updateConfig(conf);
            return res.data;
        }
        catch (err: any) {
            if (err?.response.status === 401) authService.logout();
            notifyService.sendFailMessage(err as string);
        }
    }
}

const configService = new ConfigService();
export default configService;